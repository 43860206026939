module.exports = {
  // App Info
  google_analytics_ID   : "",                                        // Enter Google Analytics ID or ""
  video_or_screenshot   : "screenshot",                                           // "screenshot" or "video"
  app_url               : "https://humans-it.com.com",                            // Domain of your website without path_prefix.
  path_prefix           : "/",                                                    // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name              : "Human's IT",
  app_description       : "We are humans, not resources",
  app_keywords          : ["Human's IT"],

  // Personal Info
  your_name              : "Human's IT",
  your_link              : "https://humans-it.com",
  your_city              : "Lyon",
  email_address          : "contact@humans-it.com",
  facebook_username      : null,
  instagram_username     : null,
  twitter_username       : "humans-it",
  youtube_username       : null,


  header_background             : "rgba(0, 0, 0, 0.0)",
  topbar_title_color            : "#ffffff",
  cover_overlay_color_rgba      : "rgba(54, 59, 61, 0.8)",
  body_background_color         : "ffffff",
  primary_text_color            : "#ffffff",
  content_width                 : "100%",
  font                          : `"Muli", serif;`,
  link_color                    : "#ffffff",
  app_title_color               : "#ffffff",
  app_price_color               : "#ffffff",
  app_description_color         : "#ffffff",
  feature_title_color           : "#ffffff",
  feature_text_color            : "#666666",
  feature_icons_foreground_color: "#1d63ea",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color : "#666666",
  social_icons_background_color : "#e6e6e6",
  footer_text_color             : "#666666",
}
