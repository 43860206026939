import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaTwitter, FaInstagram, FaFacebookF } from "react-icons/fa";


import "@fortawesome/fontawesome-free/css/all.min.css"

import configs from "../../site-config"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" keywords={configs.app_keywords} />

    <div
      className="imageWrapper"
      style={{
        backgroundImage: `url(${data.headerImage.childImageSharp.fluid.src})`,
      }}
    >
      <div className="headerBackground">
        <div className="container">
          <header>
            <Img
              fluid={data.headerIcon.childImageSharp.fluid}
              className="headerIcon"
            />
          </header>
          <div className="main">
              <Img
                fluid={data.appIconLarge.childImageSharp.fluid}
                className="appIconLarge"
              />
              {/*<div className="slogan">{configs.app_description}</div>*/}
          </div>
          {/*<footer>*/}
            {/*<div className="footerIcons">*/}
              {/*{configs.facebook_username && (*/}
                {/*<a*/}
                  {/*href={`https://facebook.com/${configs.facebook_username}`}*/}
                  {/*aria-label="Facebook"*/}
                {/*>*/}
                  {/*{FaFacebookF()}*/}

                {/*</a>*/}
              {/*)}*/}

              {/*{configs.twitter_username && (*/}
                {/*<a*/}
                  {/*href={`https://twitter.com/${configs.twitter_username}`}*/}
                  {/*aria-label="Twitter"*/}
                {/*>*/}
                  {/*{FaTwitter()}*/}

                {/*</a>*/}
              {/*)}*/}

              {/*{configs.instagram_username && (*/}
                {/*<a*/}
                  {/*href={`https://instagram.com/${configs.instagram_username}`}*/}
                  {/*aria-label="Instagram"*/}
                {/*>*/}
                  {/*{FaInstagram()}*/}
                {/*</a>*/}
              {/*)}*/}
              {/*/!*{configs.email_address && (*!/*/}
                {/*/!*<a href={`mailto:${configs.email_address}`} aria-label="Email">*!/*/}
                  {/*/!*{FaPaperPlane()}*!/*/}
                {/*/!*</a>*!/*/}
              {/*/!*)}*!/*/}
            {/*</div>*/}
          {/*</footer>*/}
          {/*TODO: Add App Store API */}
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    headerIcon: file(relativePath: { eq: "humans-it_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    appIconLarge: file(relativePath: { eq: "logo-main.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage: file(relativePath: { eq: "bg.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 2080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
