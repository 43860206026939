import { createGlobalStyle } from "styled-components"
import configs from "../../site-config"

export default createGlobalStyle`
  html {
    font-size: 62.5%;
    font-family: ${configs.font};
    line-height: 1;
  }

  body {
    font-size: 2rem;
    background-color: ${configs.body_background_color};
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 2rem;
  }

  // Better font rendering
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${configs.body_background_color};
  }

  a:link,
  a:hover,
  a:visited,
  a:active {
    color: ${configs.link_color};
    text-decoration: none;
  }

  // Various resets
  *,
  *::before,
  *::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    margin: 0;
    padding: 0;
  }

  .imageWrapper {
    // Sets the background image in the header area
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }

  .headerImage {
    position: unset !important;
    height: 0 !important;
    display: block !important;
    
  }
  .headerImage > div{
    padding-bottom: 0 !important;
  }
  .headerImage img {
    z-index: -1;
  }

  .headerBackground {
    height: 30px;
    padding: 3rem 4rem ;
    background-color: ${configs.header_background};
  }



  header {
    width: 100%;
    display: flex;
    padding: 0 24px;
    box-sizing: border-box;
  }

  .logo {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
  }

  .logo > p {
    color: ${configs.topbar_title_color};
    display: flex;
    font-weight: bold;
    padding-bottom: 1px;
  }

  .headerIcon {
    width: 132px;
    max-width: 100%;
    height: auto;
    -webkit-clip-path: url(#shape120);
    clip-path: url(#shape120);
    margin-right: 15px;
  }

  // Navigation Links
  nav {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 115px;
  }

  nav > ul {
    color: #fff;
    display: flex;
    list-style-type: none;
  }

  nav > ul li {
    padding-left: 50px;
    text-align: right;
  }

  nav > ul li:first-child {
    padding-left: 0px;
  }

  nav > ul li a:link,
  nav > ul li a:visited {
    text-decoration: none;
    color: rgba(${configs.topbar_title_color}, 0.6);
  }

  nav > ul li a:hover,
  nav > ul li a:active {
    text-decoration: none;
    color: rgba(${configs.topbar_title_color}, 1);
  }



  .main{
    height: calc(100vh - 185px);
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .slogan{
      font-size: 2.0rem;
      line-height: 3.0rem;
      font-style: italic;
      padding: 6rem;
      color: ${configs.primary_text_color};
    
      @media only screen and (max-width: 900px) {
        padding: 4rem 2rem;
        text-align: center;
      }
    }
  }



  .appIconLarge {
    width: 500px;
    height: auto;
    
    &.gatsby-image-wrapper{
    overflow: inherit!important;
    }

  
  
  @media only screen and (max-width: 992px) {
    width: 300px;
    height: auto;

  }
  }

 
  .featureText {
    margin-left: 18px;
  }


  // Footer

  footer {
    width: 100%;
    padding: 15px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: flex-start;
    
    a{
    margin-left: 20px;
    }
    
  }

  .footerIcons {
    padding-bottom: 0px;
    display: flex;
  }

  @media only screen and (max-width: 992px) {
    .footerIcons {
      display: flex;
    }
  }

  .hidden {
    display: none;
  }
`
